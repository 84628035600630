import React, { useState, Suspense } from 'react'
import { navigate } from 'gatsby'
import { useSetRecoilState } from 'recoil'
import { Col, Row, Stack } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { Loading } from '/src/components/loading'
import { AccountForm } from '/src/components/user'
import { AsyncWrapper, Button, TabsList } from '/src/components/common'
import HeroImage from '/src/components/layout/HeroImage'
import { userState } from '/src/hooks/useUser'
import { cartCountState, cartState } from '/src/hooks/useCart'
import { useUser } from '/src/hooks'
import { postRequest } from '/src/httpClient'

const UserAccountFormAsyncWrapper = () => {
  return (
    <AsyncWrapper>
      <UserAccountFormDataWrapper />
    </AsyncWrapper>
  )
}

const UserAccountFormDataWrapper = () => {
  const user = useUser()
  return <AccountForm user={user} />
}

const UserPage = () => {
  const setUserState = useSetRecoilState(userState)
  const setCartState = useSetRecoilState(cartState)
  const setCartCountState = useSetRecoilState(cartCountState)
  const [isLoading, setIsLoading] = useState(false)

  const handleLogout = () => {
    setIsLoading(true)
    postRequest('/api/user/logout').then(res => {

      setUserState(false)
      setCartState(false)
      setCartCountState(0)
      setIsLoading(false)
      navigate('/')
    })
  }

  return (
    <Layout hero={<HeroImage title="My Account" />}>
      <Row className="d-flex justify-content-center">
        <Col md={10} lg={10}>
          <Row className="mt-2 mb-4">
            <Col sm={6}>
              <div className="page-title">
                <h1 className="title">My Account</h1>
              </div>
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
              <Stack direction="horizontal" gap={4}>
                <Button disabled={isLoading} className="button outline" onClick={handleLogout}>
                  Logout
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <FontAwesomeIcon icon={faArrowRight} />
                  )}
                </Button>
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3" sm={12}>
              <TabsList items={[
                {
                  label: 'Account',
                  path: '/user'
                },
                {
                  label: 'Orders',
                  path: '/user/orders'
                },
                {
                  label: 'Enquiries',
                  path: '/user/enquiries'
                },
                {
                  label: 'Favourites',
                  path: '/user/favourites'
                }
              ]} />
            </Col>
          </Row>
          <UserAccountFormAsyncWrapper />
        </Col>
      </Row>
    </Layout>
  )
}

export default UserPage

export const Head = () => <title>My Account | Metalcorp</title>
